import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import SliderSection from '../../shared/SliderSection/SliderSection';

const HumanFeature = () => {
  const { photo } = useStaticQuery(graphql`
    {
      photo: file(relativePath: { eq: "ourProduct/humanFeature/slide-3.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
    }
  `);

  return (
    <SliderSection
      className="about-human-feature"
      type="left"
      sliderContent={[
        {
          id: 0,
          text:
            'Interested in helping us to make cybersecurity easy for everyone?',
          shapePath:
            'M554.61 247.764C578.084 344.881 424.157 498.482 289.419 517.137C174.529 533.032 34.0916 446.291 6.58431 291.752C-39.3328 33.9837 165.62 -98.2918 289.189 85.7956C388.801 234.193 540.473 164.711 554.61 247.764Z',
          image: {
            src: photo,
            flair: {
              top: -6,
              left: 70,
              rotate: 90,
            },
          },
        },
      ]}
      button={{ onClick: '/book-demo', text: 'Book a Demo' }}
    />
  );
};

export default HumanFeature;
