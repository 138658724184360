import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import './TeamSection.scss';

const renderMemberOfTheTeam = member => (
  <div className="team-section__member" key={member.name}>
    <div className="team-section__member-image-wrapper">
      <img
        className="team-section__member-icon"
        src={member.icon.publicURL}
        alt={member.name}
      />
      <Img
        className="team-section__member-image"
        fluid={member.img.childImageSharp.fluid}
      />
    </div>
    <h3 className="team-section__member-name">{member.name}</h3>
    <p className="team-section__member-position">{member.position}</p>
  </div>
);

const TeamSection = () => {
  const {
    aboutUsJson: { team },
  } = useStaticQuery(graphql`
    {
      aboutUsJson {
        team {
          name
          position
          icon {
            publicURL
          }
          img {
            childImageSharp {
              fluid {
                presentationWidth
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  `);

  return (
    <div className="team-section">
      <div className="container team-section__container">
        <h2 className="team-section__title">
          Our team is the perfect blend of behavior and learning science,
          security, design, and technology.
        </h2>
        <div className="team-section__team-wrapper">
          {team.map(renderMemberOfTheTeam)}
        </div>
      </div>
    </div>
  );
};

export default TeamSection;
