import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import QuoteSection from '../../shared/QuoteSection/QuoteSection';
import QuoteIcon3 from '../../../icons/ilustration_03.inline.svg';
import shapePaths from '../../../utils/shapePaths';

const FirstQuote = () => {
  const { thumbnail } = useStaticQuery(graphql`
    {
      thumbnail: file(relativePath: { eq: "rectangles/07.png" }) {
        childImageSharp {
          fixed(width: 64, height: 64) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);

  return (
    <QuoteSection
      type="left"
      content={
        <span>
          “We know that security may look technical, but it’s all about people
          and how they behave. Our focus on humans sets us apart in
          cybersecurity.”
        </span>
      }
      shapePath={shapePaths[8]}
      author={{
        name: 'Steven Dean',
        title: 'Founder & Product',
        image: thumbnail.childImageSharp.fixed,
      }}
      image={<QuoteIcon3 />}
    />
  );
};

export default FirstQuote;
