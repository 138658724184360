import React from 'react';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import shapePaths from '../../../utils/shapePaths';
import SliderFlair from '../../../icons/sliderFlair.inline.svg';
import Dust from '../../../icons/dust.inline.svg';

import './IntroSection.scss';

const IntroSection = () => {
  const { introPhoto } = useStaticQuery(graphql`
    {
      introPhoto: file(
        relativePath: { eq: "aboutUs/introSection/image_01.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <div className="about-us-intro">
      <div className="container about-us-intro__container">
        <div className="about-us-intro__wrapper">
          <h1 className="about-us-intro__title">
            How work gets done looks different every day.
          </h1>
          <p className="about-us-intro__description">
            Work is happening in offices, homes, and coffee shops. Information
            is created and shared on multiple devices, apps, and cloud services.
            Staying secure is a huge, complicated and often confusing task. We
            think everyone deserves better.
          </p>
          <div className="about-us-intro__image-wrapper">
            <Dust className="about-us-intro__image-dust" />
            <div className="about-us-intro__slider-flair">
              <SliderFlair />
            </div>
            <Img
              className="about-us-intro__image"
              fluid={introPhoto.childImageSharp.fluid}
            />
            <svg
              viewBox="0 0 563 524"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="about-us-intro__shape"
            >
              <path d={shapePaths[9]} fill="#0DB876" />
            </svg>
          </div>
          <h2 className="about-us-intro__sub-title">
            We’re redesigning security for people.
          </h2>
          <p className="about-us-intro__content">
            At Edwin, we believe you shouldn’t need to be a cybersecurity expert
            to understand what you need to do to get and stay secure, so we’re
            building a personal security platform with you at the center. A way
            to secure your work, life, and family – all in one place. Guiding
            you through the steps you need to take, and helping you to learn and
            practice as you go.
          </p>
        </div>
      </div>
    </div>
  );
};

export default IntroSection;
