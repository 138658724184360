import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import QuoteSection from '../../shared/QuoteSection/QuoteSection';
import QuoteIcon2 from '../../../icons/ilustration_02.inline.svg';
import shapePaths from '../../../utils/shapePaths';

const SecondQuote = () => {
  const { thumbnail } = useStaticQuery(graphql`
    {
      thumbnail: file(relativePath: { eq: "rectangles/06.png" }) {
        childImageSharp {
          fixed(width: 64, height: 64) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);

  return (
    <QuoteSection
      type="right"
      content={
        <span>
          “Our team is the perfect mix of expertise to help bring design-led
          human behavior change to life within your organization.”
        </span>
      }
      shapePath={shapePaths[3]}
      author={{
        name: 'Amit Lubling',
        title: 'Edwin Co-Founder & CEO',
        image: thumbnail.childImageSharp.fixed,
      }}
      image={<QuoteIcon2 />}
    />
  );
};

export default SecondQuote;
